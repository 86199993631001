<template>
  <div class="page page--bottom-indent page__delegations delegations">
    <div class="delegations__empty" v-if="!delegations || !delegations.length">
      <h2 class="delegations__title">
        Делегирование полномочий
      </h2>
      <p class="delegations__text">
        У вас нет ни одного активного делегирования. Добавьте новое правило.
      </p>
      <esmp-icon
        class="delegations__image"
        name="delegations"
      />
      <esmp-icon
        class="delegations__image-arrow"
        name="delegationArrow"
      />
    </div>
    <div class="delegation__list" v-if="delegations.length">
      <esmp-tabs value="active">
        <esmp-tab-pane
          label="Активные"
          :counter="activeDelegations.length"
          name="active"
        >
          <delegation-item
            v-for="item in activeDelegations"
            :key="item.id"
            :item="item"
            @toggle-modal="toggleModal"
          />
        </esmp-tab-pane>
        <esmp-tab-pane
          label="Неактивные"
          :counter="inactiveDelegations.length"
          name="inactive"
        >
          <delegation-item
            v-for="item in inactiveDelegations"
            :key="item.id"
            :item="item"
            @toggle-modal="toggleModal"
          />
        </esmp-tab-pane>
      </esmp-tabs>
    </div>
    <div :class="['page__action-buttons', { 'page__action-buttons--wide': isCollapsedMenu}]">
      <esmp-button @click="createNewDelegation">
        Добавить новое правило
      </esmp-button>
    </div>
    <modal-delegation-prolongation
      :is-show="showModals.ModalDelegationProlongation"
      :delegation="updatedDelegation"
      @toggle-modal="toggleModal"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DelegationItem from '@/components/delegation-item';
import ModalDelegationProlongation from '@/components/modals/modal-delegation-prolongation';

export default {
  name: 'Delegations',
  components: {
    DelegationItem,
    ModalDelegationProlongation,
  },
  data() {
    return {
      showModals: {
        ModalDelegationProlongation: false,
      },
      updatedDelegation: null,
    };
  },
  computed: {
    ...mapState('sidebar', ['isCollapsedMenu']),
    ...mapState('delegations', ['delegations']),
    activeDelegations() {
      if (this.delegations && this.delegations.length) {
        return this.delegations.filter((delegation) => delegation.isActive);
      }
      return null;
    },
    inactiveDelegations() {
      if (this.delegations && this.delegations.length) {
        return this.delegations.filter((delegation) => !delegation.isActive);
      }
      return null;
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    ...mapActions('delegations', ['getDelegations']),
    createNewDelegation() {
      this.$router.push('/delegations/create/delegation');
    },
    toggleModal(payload) {
      this.showModals[payload.modalName] = payload.modalState;
      this.updatedDelegation = payload.delegation || null;
      if (payload.modalName === 'ModalDelegationProlongation' && !payload.modalState) this.init();
    },
    init() {
      this.setLoading({ key: 'page', value: true });
      this.getDelegations().finally(() => {
        this.setLoading({ key: 'page', value: false });
      });
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss">
.delegations {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &__empty {
    background-color: $color-white;
    border-radius: 16px;
    width: 823px;
    padding: 32px;
    position: relative;
    margin-bottom: 223px;

    @include for-size(phone-landscape-down) {
      width: 100%;
      padding: 24px;
    }
    @include for-size(phone-portrait-down) {
      padding: 20px;
      margin-bottom: 180px;
    }
  }

  &__title {
    margin-bottom: 24px;
    font-size: 22px;
    line-height: 24px;
    width: 310px;

    @include for-size(phone-portrait-down) {
      width: 100%;
      margin-bottom: 16px;
      font-size: 20px;
      line-height: 22px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
    width: 310px;

    @include for-size(phone-portrait-down) {
      width: 100%;
    }
  }

  &__image {
    width: 251px;
    height: 160px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    @include for-size(phone-portrait-down) {
      display: none;
    }
  }

  &__image-arrow {
    width: 264.44px;
    height: 327.25px;
    position: absolute;
    left: 0;
    bottom: -255px;

    @include for-size(phone-portrait-down) {
      width: 200px;
      height: 267px;
      bottom: -200px;
    }
  }
}
</style>
